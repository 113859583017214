import { Suspense, lazy, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import Dropdown from "../../../../marketing-cloud-main/src/pages/settingsComponents/Engagements/whatsappTemplateTab/dropdown-component/dropdownComponent";

const AttributesList = lazy(() => import("./attributesList"));
const SegmentsList = lazy(() => import("./segmentsList"));
const PostSegment = lazy(() => import("./postSegment"));
const RenderAttribute = lazy(() => import("./renderAttribute"));

import Spinner from "../../common/assets/Spinner";
import { getAccountStat } from "../../../../marketing-cloud-main/src/common/apis/billing";
import { getAudienceById } from "../../../../marketing-cloud-main/src/common/apis/audience";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { formatNumbers } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { ChevronDown, Close, Segments } from "../../common/assets/icons";
import {
  getDataSources,
  getPreviewCount,
  getattributes,
} from "./apis/segmentApis";

const AudienceApp = (props) => {
  let {
    navigate,
    setTargetProfiles,
    customAudiencePayload,
    setCustomAudiencePayload,
    tenantContext,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(true);
  const [dataSource, setDataSource] = useState("");
  const [attributes, setAttributes] = useState([]);

  const [targetedSegments, settargetedSegments] = useState([]);
  const [targetedSegmentsCount, settargetedSegmntsCount] = useState(0);
  const [previewcount, setPreviewCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [tierId, setTierId] = useState(0);
  const [profileStatusCount, setProfileStatusCount] = useState();

  const fetchDataSources = () => {
    try {
      setLoading(true);
      getDataSources()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setDataListItem(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve data sources.",
              message: error,
            });
            setDataListItem([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const f = urlParams.get("f");
    if (!f) {
      getUpdatedCount(attributes);
    }
  }, [dataSource, targetedSegments]);

  useEffect(() => {
    if (customAudiencePayload) {
      getAttributesFRomPayload(customAudiencePayload);
    }
    checkQuesryString();
    fetchDataSources();
    getPlan();
  }, []);

  const getPlan = () => {
    getAccountStat()
      .then((response) => {
        let id = JSON.parse(response.subscriptions[0])[0].tier_id;
        setTierId(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDataListItem = (dataSources) => {
    let list = [];

    dataSources.forEach((dataSource) => {
      if (!(parent !== window && dataSource.dataType === "terragon's data")) {
        list.push({
          label: (
            <div>
              <div className="flex justify-start gap-2">
                <div className={`leading-6 text-sm font-semibold capitalize`}>
                  {dataSource?.dataType}
                </div>
                {!dataSource?.isEnabled && (
                  <div className="bg-blue-50 text-blue-800 font-semibold text-[10px] rounded-full flex items-center px-2 -mt-1">
                    Upgrade plan to use
                  </div>
                )}
              </div>
              <div className="text-gray-500 max-w-[180px] text-xs mt-0.5">
                {dataSource?.description}
              </div>
            </div>
          ),
          value: dataSource?.dataType,
          isAvailable: dataSource?.isAvailable,
          isEnabled: dataSource?.isEnabled,
        });
      }
    });
    setDataList(list);
  };

  const getDataSourcePayload = () => {
    if (dataSource === "terragon's data") {
      return { audience_source: "terragon" };
    } else if (dataSource === "my own data") {
      return { audience_source: "csv", segment_ids: targetedSegments };
    }
  };

  const getPayload = (attributes) => {
    let query = [];
    let interest = { include: [] };
    attributes?.forEach((attribute) => {
      attribute.forEach((el) => {
        let operator = el.selector;
        let criteria = el.criteria;
        if (el.criteria !== "interest") {
          if (el.values.length !== 0) {
            switch (operator) {
              case "Equals":
              case "Is exactly":
                query.push({ [criteria]: { $in: el.values } });
                break;
              case "Contains":
                query.push({ [criteria]: { $like: "%" + el.values[0] + "%" } });
                break;
              case "Is not equal to":
                query.push({ [criteria]: { $nin: el.values } });
                break;
              case "Starts with":
                query.push({ [criteria]: { $like: el.values[0] + "%" } });
                break;
              case "Ends with":
                query.push({ [criteria]: { $like: "%" + el.values[0] } });
                break;
              case "Is between":
                query.push({
                  [criteria]: {
                    $between: { $min: el.values[0], $max: el.values[1] },
                  },
                });
                break;
              case "Is less than":
                query.push({ [criteria]: { $lt: el.values[0] } });
                break;
              case "Is greater than":
                query.push({ [criteria]: { $gt: el.values[0] } });
                break;
            }
          }
        } else {
          switch (operator) {
            case "Equals":
              interest["include"] = [...interest["include"], ...el.values];
              break;
          }
        }
      });
    });
    let data = {
      query: query,
      interests: interest,
    };

    let payload = {
      ...data,
      ...getDataSourcePayload(),
      profile_status_count: profileStatusCount,
    };
    return payload;
  };

  const reversePayload = (payload, attr, segments) => {
    let attributes = [];

    // Extract data and interests from the payload
    let { query, interests, ...dataSourcePayload } = payload;

    // Iterate through the query array
    query?.forEach((condition) => {
      let operator;
      let criteria;
      let values;

      // Iterate through the condition object
      for (const key in condition) {
        operator = Object.keys(condition[key])[0];
        criteria = key;
        values = condition[key][operator];
      }

      let attrType =
        attr[
          attr.findIndex((x) => {
            return (
              x.column_name === criteria ||
              x.group_name === criteria.split("_")[0]
            );
          })
        ];

      let attrDataType;
      if (attrType.group_name) {
        attrDataType = attrType.properties.find(
          (el) => el.column_name === criteria
        );

        // Reconstruct the attribute based on the operator and criteria
        if (attrType.group_name) {
          if (attrDataType.dependency) {
            let index = attributes.findIndex(
              (el) => el[0].criteria.split("_")[0] === criteria.split("_")[0]
            );
            if (!index) {
              let attribute = [
                {
                  selector: getOperatorNameForValue(operator, values),
                  criteria: criteria,
                  values: getValueFromPayload(values, operator),
                  category: "Data Upload",
                  type: attrDataType.data_type,
                  dependency: attrDataType.dependency?.length,
                },
              ];
              attributes.push(attribute);
            } else {
              let position = attrDataType.dependency.length;
              attributes[index][position] = {
                selector: getOperatorNameForValue(operator, values),
                criteria: criteria,
                values: getValueFromPayload(values, operator),
                category: "Data Upload",
                type: attrDataType.data_type,
                dependency: attrDataType.dependency?.length,
              };
            }
          } else {
            let attribute = [
              {
                selector: getOperatorNameForValue(operator, values),
                criteria: criteria,
                values: getValueFromPayload(values, operator),
                category: "Data Upload",
                type: attrDataType.data_type,
              },
            ];
            attributes.push(attribute);
          }
        }
      } else {
        attrDataType === attrType.data_type;
        // Reconstruct the attribute based on the operator and criteria
        let attribute = [
          {
            selector: getOperatorNameForValue(operator, values),
            criteria: criteria,
            values: getValueFromPayload(values, operator),
            category: "Data Upload",
            type: attrType.data_type,
          },
        ];
        attributes.push(attribute);
      }
    });

    // Handle interests
    if (interests && interests.include.length > 0) {
      let interestAttribute = [
        { selector: "Equals", criteria: "interest", values: interests.include },
      ];
      attributes.push(interestAttribute);
    }
    // Reconstruct the payload
    let reversedPayload = { attributes, ...dataSourcePayload };

    setAttributes(reversedPayload.attributes);

    getPreviewCountOnEdit(reversedPayload.attributes, segments);
  };

  const getUpdatedCount = (props?) => {
    if (!dataSource) return;
    // if (dataSource !== "terragon's data" && targetedSegments.length === 0) {
    //   return;
    // }

    let payload = getPayload(props ? props : attributes);

    if (setCustomAudiencePayload) setCustomAudiencePayload(payload); //for the custom audience creation during the campaign creation flow

    setLoading(true);

    try {
      getPreviewCount(payload)
        .then((res) => {
          setLoading(false);
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setProfileStatusCount(res.data.profile_status_count);
            setPreviewCount(res.total || 0);
            if (setTargetProfiles) {
              setTargetProfiles(res.total || 0);
            } //for the custom audience creation during the campaign creation flow
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          let { response } = err;
          //if (response.data?.message === "No data found with query")
          setPreviewCount(0);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setLoading(false);
    }
  };

  const [showDataPickModal, setShowDataPickModal] = useState(false);

  const [showAttributeCreationModal, setShowAttributeCreationModal] =
    useState(false);

  const [showSegmentConfirmationModal, setShowSegmentConfirmationModal] =
    useState(false);

  const addAttribute = (props) => {
    setAttributes([
      ...attributes,
      props,
      // {
      //   category: props.category,
      //   criteria: props.criteria,
      //   type: props.type,
      //
      // },
    ]);
  };

  const deleteAttribute = (i) => {
    let nextAttributes = attributes.filter((el, index) => index !== i);
    setAttributes(nextAttributes);
    getUpdatedCount(nextAttributes);
  };

  const checkQuesryString = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const f = urlParams.get("f");
    if (f) {
      getAudienceById(f)
        .then((response) => {
          getAttributesFRomPayload(JSON.parse(response.data[0].request_query));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getAttributesFRomPayload = (payload) => {
    //setPreviewCount(payload.audience_size);
    if (payload.audience_source === "csv") {
      setDataSource("my own data");
      settargetedSegments(payload.segment_ids);
      setAttributesFromPayload(payload, "csv", payload.segment_ids);
    } else {
      setDataSource("terragon's data");
      setAttributesFromPayload(payload, "terragon", []);
    }
  };

  const setAttributesFromPayload = (payload, source, segments) => {
    setLoading(true);
    getattributes(source)
      .then((res) => {
        setLoading(false);
        const { error, data = [] } = res;
        if (!error || error === "false") {
          reversePayload(payload, data, segments);
        } else {
          console.log("Error");
        }
      })
      .catch((response) => {
        setLoading(false);
        console.log("Error", response);
      });
  };

  const getOperatorNameForValue = (operator, value) => {
    let v = "";
    if (typeof value === "object") v = value[0];
    else v = value;
    switch (operator) {
      case "$in":
        return "Equals";
      case "$nin":
        return "Is not equal to";
      case "$like":
        if (v[0] === "%" && v[v.length - 1] === "%") return "Contains";
        if (v[0] === "%") return "Ends with";
        if (v[v.length - 1] === "%") return "Starts with";
        break;
      case "$between":
        return "Is between";
      case "$lt":
        return "Is less than";
      case "$gt":
        return "Is greater than";
      default:
        break;
    }
    return operator;
  };

  const getValueFromPayload = (value, operator) => {
    let v = [];
    if (typeof value === "object") {
      if (operator === "$between") {
        return [value.$min, value.$max];
      } else {
        value.map((x) => {
          v.push(cleanValue(x));
        });
        return v;
      }
    } else return [cleanValue(value)];
  };

  const cleanValue = (x) => {
    if (x[0] === "%" && x[x.length - 1] === "%")
      return x.substring(1, x.length - 1);
    else if (x[0] === "%") return x.substring(1, x.length);
    else if (x[x.length - 1] === "%") return x.substring(0, x.length - 1);
    else return x;
  };

  const getPreviewCountOnEdit = (attr, segments) => {
    let payload = getPayload(attr);
    payload.audience_source
      ? null
      : (payload.audience_source = segments.length > 0 ? "csv" : "terragon");
    payload.segment_ids = segments;

    if (setCustomAudiencePayload) setCustomAudiencePayload(payload); //for the custom audience creation during the campaign creation flow

    setLoading(true);

    try {
      getPreviewCount(payload)
        .then((res) => {
          setLoading(false);
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setProfileStatusCount(res.data.profile_status_count);
            setPreviewCount(res.total || 0);
            if (setTargetProfiles) {
              setTargetProfiles(res.total || 0);
            } //for the custom audience creation during the campaign creation flow
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          let { response } = err;
          //if (response.data?.message === "No data found with query")
          setPreviewCount(0);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <Header
       pageName={
         <div className="flex items-center gap-3">
           <span className="text-gray-600">Segments</span> <ChevronRight />{" "}
           Create segment
         </div>
       }
     /> */}
      <div className="relative px-10 py-8">
        {loading ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-zinc-100 bg-opacity-50 z-[100000]"></div>
        ) : null}
        {showHelpModal && !(props.screen === "campaign") && (
          <div className=" flex justify-between p-4 bg-gray-25 border border-gray-100 rounded-lg mb-10">
            <div>
              <div className="text-base font-semibold pb-2">
                Need help creating an audience?
              </div>
              <div className="text-sm leading-6 text-gray-500">
                Check out step-by-step guides or ask our team for help with
                creating an audience.
              </div>
              <button
                className="py-3 mt-4 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                //   onClick={() => setShowEmptyState(false)}
              >
                Learn more
              </button>
            </div>
            <div className="flex gap-2">
              <img src={Segments} alt="" loading="lazy" />
              <div
                onClick={() => setShowHelpModal(false)}
                className="cursor-pointer "
              >
                <img src={Close} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="text-base font-semibold ">
            Create a custom audience
          </div>
          <div className="text-sm leading-6 text-gray-500 pt-2 mb-10">
            Build a custom audience applying different attributes and events
          </div>
          <div className="flex justify-between gap-3">
            <div>
              <div className="flex items-center gap-3">
                <div className="text-base">Profile matching</div>
                <Dropdown
                  options={dataList}
                  value={dataSource}
                  placeholder={"Select Data Source"}
                  handleChange={(value) => {
                    if (value === `terragon's data` && tierId === 0) {
                      navigate("/pricing");
                    } else {
                      setDataSource(value);
                      setAttributes([]);
                    }
                  }}
                  className={`w-full rounded-full text-sm font-semibold text-gray-700 bg-gray-10`}
                />
                {dataSource === `my own data` && (
                  <div
                    onClick={() => {
                      setShowDataPickModal(true);
                    }}
                    className={`flex items-center gap-2 cursor-pointer rounded-full p-3 text-sm font-semibold text-gray-700 bg-gray-10`}
                  >
                    {targetedSegments.length === 0 ? (
                      <span className="text-gray-500 font-normal">
                        Select connected data
                      </span>
                    ) : (
                      `${targetedSegments.length} segments selected`
                    )}
                    <img src={ChevronDown} alt="" loading="lazy" />
                    {showDataPickModal && (
                      <Suspense fallback={<div>Loading</div>}>
                        <SegmentsList
                          closeModal={() => {
                            setShowDataPickModal(false);
                          }}
                          targetedSegments={targetedSegments}
                          targetedSegmentsCount={targetedSegmentsCount}
                          settargetedSegmentsCount={settargetedSegmntsCount}
                          settargetedSegments={settargetedSegments}
                        />
                      </Suspense>
                    )}
                  </div>
                )}

                <div className="text-base">and conditions below</div>
              </div>
              {attributes.map((attribute, i) => {
                return (
                  <div
                    key={i}
                    //className={`${attribute.length !== 1 && "bg-gray-60"}`}
                  >
                    {attribute.map((subAttribute, innerIndex) => {
                      return (
                        <div
                          key={innerIndex}
                          className={`my-5 p-2 ${
                            subAttribute.dependency ? "ml-10" : ""
                          }`}
                        >
                          <Suspense fallback={<div>Loading</div>}>
                            <RenderAttribute
                              attributes={attribute}
                              mainAttribute={i}
                              setAttributes={(props) => {
                                let nextAttributes = attributes.map(
                                  (ele, index) => {
                                    if (index === i) {
                                      return props;
                                    } else {
                                      return ele;
                                    }
                                  }
                                );
                                setAttributes(nextAttributes);
                                return nextAttributes;
                              }}
                              i={innerIndex}
                              deleteAttribute={deleteAttribute}
                              getUpdatedCount={getUpdatedCount}
                              dataSource={dataSource}
                              segments={targetedSegments}
                            />
                          </Suspense>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className="text-sm leading-4 font-semibold mt-8">
                <span
                  className="cursor-pointer"
                  onClick={() => setShowAttributeCreationModal(true)}
                >
                  Add an attribute
                </span>{" "}
                <span className="text-gray-400 px-5 cursor-default">|</span>{" "}
                <span className="cursor-not-allowed">Add event</span>
              </div>
            </div>
            {!(props.screen === "campaign") && (
              <div className="w-[300px] border border-gray-200 rounded-lg">
                <div className="px-6 py-5 border-b font-semibold">
                  Matched profiles
                </div>
                <div className="p-8 pt-5">
                  <div className="text-2xl font-semibold pb-2">
                    {loading ? <Spinner className={"w-6 h-6"} /> : formatNumbers(previewcount)}
                  </div>
                  <div className="text-gray-500 text-sm leading-6 mb-8">
                    Based on your selection, these number of profiles match your
                    audience data
                  </div>
                  <button
                    className="rounded-lg py-3 px-5 hover:bg-green-75 hover:border-green-75 bg-blue-75 border-blue-75 text-white font-semibold text-sm leading-4 cursor-pointer"
                    onClick={() => {
                      setShowSegmentConfirmationModal(true);
                    }}
                  >
                    Create audience
                  </button>
                </div>
              </div>
            )}
          </div>

          {showAttributeCreationModal && (
            <Suspense fallback={<div>Loading</div>}>
              <AttributesList
                closeModal={() => setShowAttributeCreationModal(false)}
                dataSource={dataSource}
                addAttribute={addAttribute}
                segmentId={targetedSegments}
              />
            </Suspense>
          )}
          {showSegmentConfirmationModal && (
            <Suspense fallback={<div>Loading</div>}>
              <PostSegment
                closeModal={() => setShowSegmentConfirmationModal(false)}
                getPayload={getPayload}
                attributes={attributes}
                audienceSize={previewcount}
                navigate={navigate}
              />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudienceApp;
