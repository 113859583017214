import { createPopper } from "@popperjs/core";

export const openDropdown = (event, dropdownID) => {
  let element = event.target;
  while (element.nodeName !== "BUTTON") {
    element = element.parentNode;
  }
  var popper = createPopper(element, document.getElementById(dropdownID), {
    placement: "bottom-start",
  });
  document.getElementById(dropdownID).classList.toggle("hidden");
  document.getElementById(dropdownID).classList.toggle("block");
};

export const getNameInitials = (nameString: string): string => {
  nameString = nameString?.trim() || "";
  let rv = nameString.charAt(0);
  const splittedName = nameString.replace(/\s+/g, " ").split(" ") || [];

  if (splittedName.length > 1) {
    rv += splittedName[1].charAt(0);
  }

  return rv.toUpperCase();
};

export const language = (language) => {
  switch (language?.trim().toLowerCase()) {
    case "en":
      return "English";
    case "en_us":
      return "English (US)";
    case "en_uk":
      return "English (UK)";
  }
};

export const colorCode = (status) => {
  switch (status?.trim().toLowerCase()) {
    case "active":
    case "resume":
    case "resumed":
    case "verified":
      return "bg-green-5 text-primary-green";
    case "approved":
      return "bg-green-5 text-primary-green";
    case "enabled":
    case "running":
      return "bg-green-5 text-primary-green";
    case "completed":
      return "bg-green-5 text-primary-green";
    case "delayed":
    case "disabled":
    case "suspended":
      return "bg-gray-100 text-gray-700";
    case "broken":
    case "canceled":
    case "cancelled":
    case "deactivated":
    case "rejected":
      return "bg-error-50 text-error-700";
    case "draft":
    case "pending":
    case "pending approval":
      return "bg-warning-50 text-error-700";
    case "pause":
    case "paused":
    case "incomplete":
      return "bg-warning-50 text-warning-600";
    case "not started":
      return "bg-light-purple text-dark-purple";
    case "scheduled":
      return "bg-light-blue text-dark-blue";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

export const formatNumbers = (num: any) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return "";
  str = str.trim().toLowerCase();
  const value = str.charAt(0).toUpperCase() + str.slice(1);
  return value;
};
