import { useEffect, useState } from "react";
import { MESSAGE } from "../../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { Duplicate, EngageSegments } from "../../../common/assets/icons";

const SegmentSideMenuBar = (props: any) => {
  const { tenantContext } = props;
  return (
    <div className="cursor-default shadow-md bg-white w-56 rounded-lg">
      <div className="group">
        <button
          onClick={() => props.navigate(`/engagements?new=1`)}
          className={`flex px-4 gap-3 py-2.5 items-center rounded w-full ${
            tenantContext.productList["create_message_policy"] &&
            "hover:bg-gray-100"
          }
          `}
          disabled={!tenantContext.productList["create_message_policy"]}
        >
          <div className="flex justify-center items-center">
            <img src={EngageSegments} alt="" loading="lazy" />
          </div>
          <div className="text-gray-700 flex justify-center items-center text-base font-medium leading-6">
            Engage segment
          </div>
        </button>

        <div className="absolute hidden group-hover:flex items-center -mt-11">
          <div className="w-[300px] p-3 z-10 -ml-[306px] bg-black text-white text-xs font-normal rounded-lg">
            {tenantContext.productList["create_message_policy"]
              ? "Engage segment using your preferred channel."
              : MESSAGE}
          </div>
        </div>
      </div>

      {/* <button
        onClick={() => console.log("Edit segment")}
        className="flex px-4 gap-3 py-2.5 items-center w-full rounded hover:bg-gray-100"
      >
        <div className=" flex justify-center items-center">
          <Edit />
        </div>
        <div className="text-gray-700 pt-2 flex justify-center items-center text-base font-normal leading-6">
          {" "}
          Edit segment
        </div>
      </button> */}
      {props.isEditable && (
        <div className="group">
          <button
            onClick={() => {
              tenantContext.productList["audience_create_policy"]
                ? props.navigate(`/audience/create-segment?f=${props.id}`)
                : null;
            }}
            className={`flex gap-3 px-4 py-2.5 items-center w-full rounded ${
              tenantContext.productList["audience_create_policy"] &&
              "hover:bg-gray-100"
            }`}
            disabled={!tenantContext.productList["audience_create_policy"]}
          >
            <div className=" flex justify-center items-center">
              <img src={Duplicate} alt="" loading="lazy" />
            </div>
            <div className="text-gray-700 flex justify-center items-center text-base font-normal leading-6">
              Duplicate and Edit
            </div>
          </button>

          <div className="absolute hidden group-hover:flex items-center -mt-16">
            <div className="w-[300px] p-3 z-10 -ml-[306px] bg-black text-white text-xs font-normal rounded-lg">
              {tenantContext.productList["audience_create_policy"]
                ? "Create a copy and modify segment. This allows to retain the original segment and make changes to a copy of this segment."
                : MESSAGE}
            </div>
            {/* <div className="w-4 h-4 -ml-3 bg-gray-900 rotate-45"></div> */}
          </div>
        </div>
      )}
      {/* <button
        onClick={() => {
          console.log("Delete");
        }}
        className="flex gap-3 px-4 py-2.5 items-center w-full rounded hover:bg-gray-100"
      >
        <div className=" flex justify-center items-center">
          <Delete />
        </div>
        <div className="text-gray-700  flex justify-center pt-2 items-center text-base font-normal leading-6">
          {" "}
          Delete
        </div>
      </button> */}
    </div>
  );
};

export default SegmentSideMenuBar;
